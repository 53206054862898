import { getServerSession } from 'next-auth/next';
import { getCsrfToken, getProviders } from 'next-auth/react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import PropTypes from 'prop-types';

import SignInView from '../../src/pages/SignIn/SignInView';
import { authOptions } from '../api/auth/[...nextauth]';

const SignIn = ({ providers, csrfToken }) => (
  <SignInView providers={providers} csrfToken={csrfToken} />
);

SignIn.getLayout = (page) => page;

SignIn.propTypes = {
  providers: PropTypes.shape({}).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export async function getServerSideProps({ locale, req, res }) {
  const session = await getServerSession(req, res, authOptions);
  const providers = await getProviders();
  const csrfToken = await getCsrfToken({ req });

  if (session && session?.user && !session?.error) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'auth'])),
      providers,
      csrfToken,
    },
  };
}

export default SignIn;
